import React from 'react';
import { PageProps } from 'gatsby';

import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import Layout, { LayoutOptions } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import { Accordion, AccordionItem } from '../../components/Accordion/Accordion';
import { useLocaleContext } from '../../context/Locale';
import DownloadBlock from '../../components/DownloadBlock/DownloadBlock';
import './style.scss';

type Props = PageProps & WithTranslation;

const RegulatoryInformation: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    page: {
      title: t('heading'),
    },
  };

  const generateLabel = (date: Date, suffix = t('disclosuresLabel')) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const result = new Intl.DateTimeFormat(`${activeLocaleTag}-CA`, options as any).format(date);

    return `${result} ${suffix}`;
  };

  const labels = {
    english: t(`${LocaleNameSpaceKeys.COMMON}:english`),
    french: t(`${LocaleNameSpaceKeys.COMMON}:french`),
  };

  const downloadBase = `/downloads/regulatory-information/`;

  const getDownloadBlock = (englishLink: string, frenchLink?: string) => {
    const downloadArray = [
      {
        to: `${downloadBase}${englishLink}`,
        label: labels.english,
      },
    ];

    if (frenchLink) {
      downloadArray.push({
        to: `${downloadBase}${frenchLink}`,
        label: labels.french,
      });
    }

    return downloadArray;
  };

  const basePillarArr = [
    {
      label: '2017',
      downloads: [
        {
          heading: generateLabel(new Date('December-31-2017')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2017/Pillar-III-Disclosures-Dec-2017-en.pdf'
          ),
        },
      ],
    },
    {
      label: '2018',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2018')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2018/Pillar-III-Disclosures-Mar-2018-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2018')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2018/Pillar-III-Disclosures-June-2018-Final-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2018')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2018/Pillar-III-Disclosures-Sep-2018-Final-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2018')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2018/Pillar-III-Disclosures-Dec-2018-Final-en.pdf'
          ),
        },
      ],
    },
    {
      label: '2019',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2019')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2019/Pillar-III-Disclosures-Mar-2019-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2019')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2019/Pillar-III-Disclosures-June-2019-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2019')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2019/Pillar-III-Disclosures-Sep-2019-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2019')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2019/Pillar-III-Disclosures-Dec-2019-en.pdf'
          ),
        },
      ],
    },
    {
      label: '2020',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2020')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2020/Pillar-III-Disclosures-Mar-2020-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2020')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2020/Pillar-III-Disclosures-Jun-2020-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('Sept-30-2020')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2020/Pillar-III-Disclosures-Sep-2020-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2020')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2020/Pillar-III-Disclosures-Dec-2020-en.pdf'
          ),
        },
      ],
    },
    {
      label: '2021',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2021')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2021/Pillar-III-Disclosures-Mar-2021-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2021')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2021/Pillar-III-Disclosures-Jun-2021-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2021')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2021/Pillar-III-Disclosures-Sep-2021-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2021')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2021/Pillar-III-Disclosures-Dec-2021-en.pdf'
          ),
        },
      ],
    },
    {
      label: '2022',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2022')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2022/Pillar-III-Disclosures-Mar-2022-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2022')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2022/Pillar-III-Disclosures-Jun-2022-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2022')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2022/Pillar-III-Disclosures-Sep-2022-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2022')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2022/Pillar-III-Disclosures-Dec-2022-en.pdf'
          ),
        },
      ],
    },
    {
      label: '2023',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2023')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2023/Pillar-III-Disclosures-Mar-2023-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2023')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2023/Pillar-III-Disclosures-Jun-2023-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2023')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2023/Pillar-III-Disclosures-Sep-2023-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2023')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2023/Pillar-III-Disclosures-Dec-2023-en.pdf'
          ),
        },
      ],
    },
    {
      label: '2024',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2024')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2024/Pillar-III-Disclosures-Mar-2024-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('Jun-30-2024')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2024/Pillar-III-Disclosures-Jun-2024-en.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('Sep-30-2024')),
          downloadLinks: getDownloadBlock(
            'basel-pillar/2024/Pillar-III-Disclosures-Sep-2024-en.pdf'
          ),
        },
      ],
    },
  ];

  const residentialDisclosuresArr = [
    {
      label: '2018',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2018')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2018/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-2018-en.pdf',
            'residential-disclosures/2018/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-March-2018-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2018')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2018/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-June-2018-Final-en.pdf',
            'residential-disclosures/2018/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-June-2018-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2018')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2018/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-September-2018-Final-en.pdf',
            'residential-disclosures/2018/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-September-2018-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2018')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2018/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-December-2018-Final-en.pdf',
            'residential-disclosures/2018/B-20-Residential-Mortgage-Guidelines-Public Disclosures-December-2018-fr.pdf'
          ),
        },
      ],
    },
    {
      label: '2019',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2019')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2019/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-March-2019-en.pdf',
            'residential-disclosures/2019/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-March-2019-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2019')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2019/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-June-2019-en.pdf',
            'residential-disclosures/2019/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-June-2019-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2019')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2019/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-2019-en.pdf',
            'residential-disclosures/2019/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-September-2019-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2019')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2019/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Dec-en.pdf',
            'residential-disclosures/2019/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-December-2019-fr.pdf'
          ),
        },
      ],
    },
    {
      label: '2020',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2020')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2020/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-20-en.pdf',
            'residential-disclosures/2020/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-March-2020-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2020')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2020/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Jun-30-en.pdf',
            'residential-disclosures/2020/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-June-2020-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('Sept-30-2020')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2020/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-30-en.pdf',
            'residential-disclosures/2020/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-September-2020-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2020')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2020/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Dec-31-en.pdf',
            'residential-disclosures/2020/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-December-2020-fr.pdf'
          ),
        },
      ],
    },
    {
      label: '2021',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2021')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2021/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-31-en.pdf',
            'residential-disclosures/2021/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-31-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2021')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2021/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Jun-2021-en.pdf',
            'residential-disclosures/2021/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Jun-2021-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2021')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2021/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-2021-en.pdf',
            'residential-disclosures/2021/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-2021-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2021')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2021/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Dec-2021-en.pdf',
            'residential-disclosures/2021/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Dec-2021-fr.pdf'
          ),
        },
      ],
    },
    {
      label: '2022',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2022')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2022/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-31-en.pdf',
            'residential-disclosures/2022/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-31-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('June-30-2022')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2022/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Jun-30-en.pdf',
            'residential-disclosures/2022/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Jun-30-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2022')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2022/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-30-en.pdf',
            'residential-disclosures/2022/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-30-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2022')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2022/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Dec-31-en.pdf',
            'residential-disclosures/2022/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Dec-31-fr.pdf'
          ),
        },
      ],
    },
    {
      label: '2023',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2023')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2023/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-31-en.pdf',
            'residential-disclosures/2023/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-31-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('Jun-30-2023')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2023/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Jun-30-en.pdf',
            'residential-disclosures/2023/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Jun-30-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('September-30-2023')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2023/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-30-en.pdf',
            'residential-disclosures/2023/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-30-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('December-31-2023')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2023/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Dec-31-en.pdf',
            'residential-disclosures/2023/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Dec-31-fr.pdf'
          ),
        },
      ],
    },
    {
      label: '2024',
      downloads: [
        {
          heading: generateLabel(new Date('March-31-2024')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2024/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-31-en.pdf',
            'residential-disclosures/2024/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Mar-31-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('Jun-30-2024')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2024/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Jun-30-en.pdf',
            'residential-disclosures/2024/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Jun-30-fr.pdf'
          ),
        },
        {
          heading: generateLabel(new Date('Sep-30-2024')),
          downloadLinks: getDownloadBlock(
            'residential-disclosures/2024/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-30-en.pdf',
            'residential-disclosures/2024/B-20-Residential-Mortgage-Guidelines-Public-Disclosures-Sep-30-fr.pdf'
          ),
        },
      ],
    },
  ];

  const getAccordionsFrom = (arr: any[]) => {
    return (
      <Accordion allowZeroExpanded>
        {arr.map((item, i) => {
          return (
            <AccordionItem
              key={`${item.label}-${i}`}
              buttonOptions={{ label: item.label }}
              buttonClassName="h5 RegulatoryInformation__accordion-h5"
            >
              {(item.downloads as any[]).map((download: any, j) => {
                return (
                  <DownloadBlock
                    key={`download-${i}-${j}`}
                    heading={download.heading}
                    downloadLinks={download.downloadLinks}
                  />
                );
              })}
            </AccordionItem>
          );
        })}
      </Accordion>
    );
  };

  return (
    <Layout options={layoutOptions} className="RegulatoryInformation">
      <div className="ElectronicAccessAgreement__generic-gap">
        <h2 className="h4">{t('voluntaryCommitments.heading')}</h2>
        <p>{t('voluntaryCommitments.description')}</p>
        <ul>
          {(t('voluntaryCommitments.list', { returnObjects: true }) as string[]).map(
            (listItem, index) => (
              <li key={index}>{listItem}</li>
            )
          )}
        </ul>
      </div>

      <div className="ElectronicAccessAgreement__generic-gap">
        <h2 className="h4">{t('baselPillarHeading')}</h2>
        <p className="mb-2">
          <Trans i18nKey="introParagraphs" t={t} />
        </p>
        {getAccordionsFrom(basePillarArr)}
      </div>

      {/* <div className="ElectronicAccessAgreement__generic-gap">
      </div> */}

      <div className="ElectronicAccessAgreement__generic-gap">
        <h2 className="h4">{t('residentialDisclosuresHeading')}</h2>
        {getAccordionsFrom(residentialDisclosuresArr)}
      </div>
      <div className="ElectronicAccessAgreement__generic-gap">
        <p>{t('footerNote')}</p>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.REGULATORY_INFORMATION)(RegulatoryInformation);
